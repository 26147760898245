import React from 'react';
import Page from '../views/RepairShop/Features';

export default ({location}) => <Page location={location} seo={{
  title: "A car workshop service powered by data",
  lang: 'en',
  description: 'A car workshop service for every mechanic that creates a direct link to connected vehicles and lets mechanics be proactive with customers.',
  meta: [{
    name: 'keywords',
    content: 'Workshop service'
  }]
}}/>