import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Grid } from '@material-ui/core';
import i18n from '../../../../locale';
import SectionWrapper from '../../../../components/SectionWrapper';
import Feature from './Feature';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        vc1: imageSharp(fluid: {
          originalName : {eq: "Vehicle-Care-Features-1.jpg"}
        }) {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        vc2: imageSharp(fluid: {
          originalName : {eq: "Vehicle-Care-Features-2.jpg"}
        }) {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        vc3: imageSharp(fluid: {
          originalName : {eq: "Vehicle-Care-Features-3.jpg"}
        }) {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        vc4: imageSharp(fluid: {
          originalName : {eq: "Vehicle-Care-Features-4.jpg"}
        }) {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        vc5: imageSharp(fluid: {
          originalName : {eq: "Vehicle-Care-Features-5.jpg"}
        }) {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        } 
      }
    `}
    render={({ vc1, vc2, vc3, vc4, vc5 }) => (
      <SectionWrapper>
        <Grid item container xs={12} md={12} xl={12}
          alignContent='center'
          justifyContent='center'
          className={styles.container}>
          <Feature title={i18n('Direct link to vehicles 24/7')}
            text={i18n('Have remote access to relevant vehicle data like never before. It is fast, simple, and creates value for your customers.')}
            image={vc1}
            layout={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
              xl: 6
            }}/>
          <Feature title={i18n('Important warnings')}
            text={i18n('Be proactive in helping customers when an undesired situation occurs. Be notified of important warnings such as engine light on, battery low, and others.')}
            image={vc2}
            layout={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
              xl: 6
            }}/>
          <Feature title={i18n('Service and repair logbook')}
            text={i18n('Track, schedule and be notified when a vehicle is due for service or inspection. Have it always as a backup accessible also by vehicle owners.')}
            image={vc3}/>
          <Feature title={i18n('Up-to-date maintenance')}
            text={i18n('Make sure that all vehicles are well-maintained and safe to drive. Newer vehicles are being serviced properly and within service intervals to avoid issues with warranty.')}
            image={vc4}/>
          <Feature title={i18n('Reports')}
            text={i18n('Scheduled reports pointing one\'s attention to important events such as engine light on, upcoming service, and others')}
            image={vc5}/>
        </Grid>
      </SectionWrapper>
    )}/>
);