import i18n from '../../../locale';
import { repairShop } from '../../../data/sitemap';

export default [{
  name: repairShop._,
  text: i18n('Vehicle care'),
  url: i18n('_url:repair-shop')
}, {
  name: repairShop.features,
  text: i18n('Features'),
  url: i18n('_url:repair-shop-features')
}, {
  name: repairShop.priceTable,
  text: i18n('Price'),
  url: i18n('_url:repair-shop-pricing-tables')
}, {
  name: repairShop.price,
  text: i18n('Get started'),
  url: i18n('_url:repair-shop-pricing')
}]